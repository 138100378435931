import { Block, Typography } from "@xprog/prensa";
import { PrensaEngineCSSProp } from "@xprog/prensa/types";
import CallToActionOutlinedButton from "components/Buttons/CallToActionOutlinedButton.index";
import { useRouter } from "next/router";
import React from "react";

const ButtonDefault: React.FC<
  React.PropsWithChildren & { url: string; color: string }
> = ({ children, url, color }) => {
  const router= useRouter();
  const handleClickButton = () => {
    if ( url) {
      if (typeof window !== 'undefined') {
        window.location.href=url;
      }
 
    }
  };
  return (
    <CallToActionOutlinedButton
      css={{
        button: {
          backgroundColor: "transparent",
          borderColor: color || "#333333",
          height: "42px",
          width: "210px",
        },
        label: {
          color: color || "#333333",
        },
      }}
      onClick={handleClickButton}
    >
      {children}
    </CallToActionOutlinedButton>
  );
};
const Container: React.FC<React.PropsWithChildren & { backGround: string }> = ({
  children,
  backGround,
}) => {
  const containerStyles: PrensaEngineCSSProp = {
    align: ["column", "center", "top"],
    background: backGround,
    minHeight: "240px",
    pb: "$8",
    pt: "$16",
    mt: "$8",
    width: "100%",
  };
  return <Block css={containerStyles}>{children}</Block>;
};
const MainTitle: React.FC<{ children: string; color: string }> = ({
  children,
  color,
}) => {
  const mainTitleCSS: PrensaEngineCSSProp = {
    color: color || "#333333",
    fontFamily: "$primary",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "25px",
    letterSpacing: "0.2px",
    pt: "$0",
    mt: "$0",
    strong: {
      fontFamily: "$primary",
      letterSpacing: "0.2px",
    },
  };
  return (
    <Typography
      css={mainTitleCSS}
      dangerouslySetInnerHTML={{ __html: children }}
    />
  );
};
const MainPrice: React.FC<{ children: string; color: string }> = ({
  children,
  color,
}) => {
  const mainTitleCSS: PrensaEngineCSSProp = {
    align: ["column", "right", "top"],
    color: color || "#333333",
    fontFamily: "$primary",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "25px",
    letterSpacing: "0.4px",
    "span:first-child": {
      align: ["row", "center", "top"],
      fontSize: "25px",
      fontWeight: 600,
      lineHeight: "30px",
      letterSpacing: "0.2px",
      strong: {
        fontSize: "75px",
        fontWeight: 700,
        pl: "$1",
        mt: "-16px",
        lineHeight: "90px",
      },
    },
    "span:last-child": {
      fontSize: "18px",
      fontWeight: 400,
      lineHeight: "23px",
      letterSpacing: "0.2px",
      mt: "-12px",
      mr: "$2",
    },
  };
  return (
    <Typography
      css={mainTitleCSS}
      dangerouslySetInnerHTML={{ __html: children }}
    />
  );
};

const Load: React.FC<React.PropsWithChildren> = () => {
  const loadStyles: PrensaEngineCSSProp = {
    border: "4px solid rgba(0, 0, 0, 0.1)",
    borderLeftColor: "#194392",
    borderRadius: "50%",
    width: "50px",
    height: "50px",
    animation: "spin 1s linear infinite",
    margin: "auto",
    marginBottom: "60px",
  };
  return <Block css={loadStyles} />;
};

const SubText: React.FC<{ children: string; color: string }> = ({
  children,
  color,
}) => {
  const subTextCSS: PrensaEngineCSSProp = {
    color: color || "#333333",
    fontFamily: "$primary",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "17px",
    letterSpacing: "0.2px",
    pb: "$0",
    mb: "$0",
    minHeight: "50px",
    maxWidth: "212px",
    textAlign: "center",
    strong: {
      color: "#333333",
    },
  };
  return (
    <Typography
      css={subTextCSS}
      dangerouslySetInnerHTML={{ __html: children }}
    />
  );
};
export { ButtonDefault, Container, MainTitle, MainPrice, SubText, Load };
