import React, {
  createContext,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import { performLogin } from "./actions/performLogin";
import {
  getSessionDataFromCookie,
  listenCookieChange,
  removeSessionDataCookie,
  updateSessionCookie,
} from "./utils";
import { updateSession } from "./actions/updateSession";
import { useKeycloak } from "@react-keycloak/ssr";
import { KeycloakInstance } from "keycloak-js";
import { Load } from "components/Article/Restricted/RestrictedBanner.styled";
import { ArticleContainer } from "components/Article/Article.styled";
import { Container } from "./session.style";

export type SessionData = {
  id: number;
  nivelAcesso: string;
  tipoDispositivo: string;
  dataUltimaRequisicao: string;
  dataUnload: string;
  dataLogout: string;
  dataLogin: string;
  ativo: boolean;
  emailUsuario: string;
  numeroContrato: string;
  inscricaoDegustador: {
    id: number;
    responsible: string;
    emailUsuario: string;
    dataInicial: string;
    dataFinal: string;
    creditosDegustadorList: [
      {
        id: number;
        data: string;
        quantidade: number;
      }
    ];
  };
};

const LoadComponent = () =>{

  return(
    <Container>
      <Load />
    </Container>
  )
}

export const SessionDataContext = createContext<SessionData | null>(null);

const SessionProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [sessionData, setSessionData] = useState<SessionData | null>(null);
  const { keycloak, initialized } = useKeycloak<KeycloakInstance>();
  const [isCookieInitialized, setIsCookieInitialized] = useState(false);

  const fetchSessionData = async () => {
    const cookie = getSessionDataFromCookie();

    if (initialized && keycloak?.authenticated && !cookie) {
      if (!keycloak.profile?.email) {
        await keycloak.loadUserProfile();
      }

      performLogin({
        email: keycloak?.profile?.email,
        tipoDispositivo: null,
        produto: process.env.NEXT_PUBLIC_PAYWALL_PRODUCT as string,
      }).then((res) => {
        
        const tokenExpiration = keycloak?.tokenParsed?.exp
          ? keycloak.tokenParsed.exp * 1000
          : 60000 * 60;
        const currentTime = new Date().getTime();
        const timeDifference = tokenExpiration - currentTime;
        const cookieExpiration = Math.ceil(timeDifference / 1000);
        if (!res.id) {
          removeSessionDataCookie();
          setSessionData(null);
          alert(`
          Ocorreu um erro ao realizar o login. Entre em contato com a equipe de suporte. Erro: performLogin: 
          message: ${res.data.erro}       
          payload:${JSON.stringify(res.data.payload)}`)
          keycloak.logout()
        } else {
          document.cookie = `sessionData=${JSON.stringify(
            res
          )}; max-age=${cookieExpiration}; path=/`;
          setSessionData(res);
        }
      })
      ;
    }
  };



  const handleUpdateSession = async (cookie) => {
    let res = await updateSession(cookie?.id);
    if (res.data && res.status == 200) {
      updateSessionCookie(res.data);
      setSessionData(res.data);
    }
  };

  useEffect(() => {
    const cookie = getSessionDataFromCookie();

    if (initialized && keycloak?.authenticated && !cookie) {
      setIsCookieInitialized(false);
      setSessionData(null);
      fetchSessionData();
    } else if (cookie) {
      if (!cookie.id) {
        removeSessionDataCookie();
        setSessionData(null);
      }else{
        setSessionData(cookie);
      }
      setIsCookieInitialized(true);
    } else if (!keycloak?.authenticated) {
      setIsCookieInitialized(true);
    }

    const interval = setInterval(async () => {
      if (cookie) {
        await handleUpdateSession(cookie);
      }
    }, 2 * 60 * 1000);

    return () => clearInterval(interval);
  }, [initialized, keycloak?.authenticated]);

  useEffect(() => {
    const cookie = getSessionDataFromCookie();

    const handleCookieChange = () => {
      const newCookieData = getSessionDataFromCookie();
      if (newCookieData) {
        setSessionData(newCookieData);
        setIsCookieInitialized(true);
      }
    };

    const interval = listenCookieChange(() => {
      handleCookieChange();
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <SessionDataContext.Provider value={sessionData}>
      {!isCookieInitialized && <LoadComponent />}
      {isCookieInitialized && children}
    </SessionDataContext.Provider>
  );
};

export default SessionProvider;
