import { useCopyProtection } from "hooks/useCopyProtection";
import { ToastContainer } from "react-toastify";
import cookie from "cookie";
import App, { AppContext } from "next/app";

import "react-toastify/dist/ReactToastify.css";
import "../styles/globals.css";
import "../styles/player.css";
import "../styles/classi.css";
import { AuthProvider } from "providers/AuthProvider/AuthProvider";
import { NextIncomingMessage } from "next/dist/server/request-meta";
import SessionProvider from "providers/Session";

function MyApp({ Component, pageProps, cookies }) {
  useCopyProtection();
  return (
    <AuthProvider cookies={cookies}>
      <SessionProvider>
        <Component {...pageProps} />
        <ToastContainer />
      </SessionProvider>
    </AuthProvider>
  );
}

function parseCookies(req?: NextIncomingMessage) {
  if (!req || !req?.headers) {
    return {};
  }
  return cookie.parse(req?.headers?.cookie || "");
}

MyApp.getInitialProps = async (context:AppContext) => {
  const appProps = await App.getInitialProps(context);
  return {
    ...appProps,
    cookies: parseCookies(context?.ctx?.req),
  };
};

export default MyApp;
